export type CreateAgentFormType = {
    firstName: string;
    lastName: string;
    email: string;
    contactNumberCountryCode?: number;
    contactNumber: string;
    agencyName: string;
    agentType: AgentType;
    agentLicenseNumber?: string;
    agentMembership: string[];
    isSaveBillingInfo: boolean;
    couponCode?: string;
    packagePromotion?: PackagePromotions;
};

export enum AgentType {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    BUYER_SELLER = 'BUYER_SELLER',
}

export type PackageType = {
    name: string;
    category: null | 'PRO' | 'BASIC' | 'DEALS';
    type: AgentType;
    price: number;
    package: PackagePromotions;
    couponCodes?: Record<string, { title: string; finalAmount: number; disclaimer?: string; expirationDate: string }>;
};

export enum PackagePromotions {
    BUYER_BASIC_MONTHLY = 'BUYER_BASIC_MONTHLY',
    BUYER_ADVANCED_MONTHLY = 'BUYER_ADVANCED_MONTHLY',
    // BUYER_BASIC_ANNUAL = 'BUYER_BASIC_ANNUAL',
}

export type SelectedAgentType = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    contactNo: string;
    mobileNumberCountryCode: number;
    profilePicture: string;
    agency: {
        id: string;
        name: string;
        bannerColor: string;
    };
};

export enum FormStep {
    INFO,
    BILLING,
}
