import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'antd';
import Image from 'next/image';
import clsx from 'clsx';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { useMutation } from '@apollo/client';
import {
    AgentType,
    CreateAgentFormType,
    FormStep,
    PackageType,
    SelectedAgentType,
} from '~/types/create-agent-form-type';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { packages } from '~/constants/pre-portal-package';
import { linkedInTrackEvent } from '~/lib/analytics';
import createAccountHeroImg from './images/create-account-hero.png';
import paymentIllustration from './images/payment-illustration.png';

import CreateAgentFromPrePortalSignUp from './Operations.graphql';

import AccountInfo from './components/AccountInfo';
import BillingInfo from './components/BillingInfo';
import SuccessPageModal from './components/SuccessPageModal';

type Props = {
    selectedAgent?: SelectedAgentType;
    isVisible: boolean;
    onCancel: () => void;
    selectedPackage: PackageType;
};

const CreateAgentAccountModal = ({ selectedAgent, isVisible, onCancel, selectedPackage }: Props) => {
    const [form] = Form.useForm<CreateAgentFormType>();
    const stripe = useStripe();
    const elements = useElements();
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [createAgentFromPrePortalSignUp, { loading }] = useMutation(CreateAgentFromPrePortalSignUp);

    const [formStep, setFormStep] = useState<FormStep>(FormStep.INFO);

    const isAgentOld = !!selectedAgent;

    useEffect(() => {
        form.setFieldsValue({
            firstName: selectedAgent?.firstName,
            lastName: selectedAgent?.lastName,
            contactNumber: selectedAgent?.contactNo,
            contactNumberCountryCode: selectedAgent?.mobileNumberCountryCode ?? 61,
            email: selectedAgent?.email,
            agencyName: selectedAgent?.agency?.name,
            agentType: selectedPackage?.name === 'SELLING AGENT' ? AgentType.SELLER : AgentType.BUYER,
        });
    }, [selectedAgent, selectedPackage?.name, isVisible]);

    const onSubmit = async () => {
        const values: CreateAgentFormType = form.getFieldsValue(true);

        if (selectedPackage?.name !== packages.SELLING_AGENT.name) {
            const cardElement = elements.getElement(CardNumberElement);

            try {
                const { token } = await stripe.createToken(cardElement);

                if (!token) return;

                await createAgentFromPrePortalSignUp({
                    variables: {
                        input: {
                            existingAgentId: isAgentOld ? selectedAgent?.id : null,
                            agencyName: values?.agencyName,
                            email: values?.email,
                            firstName: values?.firstName,
                            lastName: values?.lastName,
                            type: values?.agentType,
                            mobileNumberCountryCode: values?.contactNumberCountryCode,
                            mobileNumber: values?.contactNumber,
                            licenseNumber: values?.agentLicenseNumber,
                            agentMembership: values?.agentMembership,
                            stripeTokenId: token?.id,
                            isSaveBillingInfo: values?.isSaveBillingInfo,
                            couponCode: values?.couponCode,
                            package: selectedPackage.package,
                        },
                    },
                });
                setShowSuccessModal(true);

                linkedInTrackEvent({ conversion_id: 16751204 });
            } catch (e) {
                Modal.error({
                    title: 'It seems there was an error in your payment method.',
                    content: (
                        <p>
                            Please contact{' '}
                            <a className="text-black" href="mailto:marketplace@realty.com.au">
                                marketplace@realty.com.au
                            </a>
                        </p>
                    ),
                });
            }
        } else {
            await createAgentFromPrePortalSignUp({
                variables: {
                    input: {
                        existingAgentId: isAgentOld ? selectedAgent?.id : null,
                        agencyName: values?.agencyName,
                        email: values?.email,
                        firstName: values?.firstName,
                        lastName: values?.lastName,
                        type: values?.agentType,
                        mobileNumberCountryCode: values?.contactNumberCountryCode,
                        mobileNumber: values?.contactNumber,
                        licenseNumber: values?.agentLicenseNumber,
                        agentMembership: values?.agentMembership,
                    },
                },
            });
            setShowSuccessModal(true);
        }

        handleClearModal();
    };

    const handleClearModal = () => {
        if (form) form.resetFields();
        setFormStep(FormStep.INFO);
        onCancel();
    };

    return (
        <>
            <SuccessPageModal
                selectedPackageName={selectedPackage?.name}
                isVisible={showSuccessModal}
                onCancel={() => setShowSuccessModal(false)}
            />
            <Modal
                visible={isVisible}
                footer={null}
                onCancel={handleClearModal}
                width={1083}
                closeIcon={<FA icon={faTimes} />}
                destroyOnClose
                centered
                className="createAgentModalStyle"
                maskStyle={{ backgroundColor: 'rgb(17 24 39 / 0.7)', backdropFilter: 'blur(8px)' }}
            >
                <div className="flex w-full flex-col md:flex-row">
                    <div className="relative flex w-full flex-col justify-between rounded-l-lg md:w-1/2 md:bg-slate-50">
                        {formStep === FormStep.BILLING && (
                            <div className="absolute left-6 top-5">
                                <FA
                                    role="button"
                                    icon={faArrowLeft}
                                    onClick={() => setFormStep(FormStep.INFO)}
                                    className="text-gray-500 duration-300 hover:text-gray-900"
                                />
                            </div>
                        )}
                        <div className="mt-4 w-full text-center lg:px-16">
                            <div className="my-10 flex items-center justify-center gap-x-2">
                                {selectedPackage?.name !== packages.SELLING_AGENT.name && (
                                    <>
                                        <div
                                            className={clsx(
                                                'flex h-6 w-6 items-center justify-center rounded-full font-bold text-white',
                                                {
                                                    'bg-primary-500': formStep === FormStep.INFO,
                                                    'bg-slate-500/50': formStep === FormStep.BILLING,
                                                }
                                            )}
                                        >
                                            1
                                        </div>
                                        <span
                                            className={clsx('font-medium', {
                                                'text-black': formStep === FormStep.INFO,
                                                'text-slate-500/50': formStep === FormStep.BILLING,
                                            })}
                                        >
                                            Account Info
                                        </span>
                                        <span className="text-slate-500/50">&mdash;</span>
                                        <div
                                            className={clsx(
                                                'flex h-6 w-6 items-center justify-center rounded-full font-bold text-white',
                                                {
                                                    'bg-primary-500': formStep === FormStep.BILLING,
                                                    'bg-slate-500/50': formStep === FormStep.INFO,
                                                }
                                            )}
                                        >
                                            2
                                        </div>
                                        <span
                                            className={clsx('font-medium', {
                                                'text-black': formStep === FormStep.BILLING,
                                                'text-slate-500/50': formStep === FormStep.INFO,
                                            })}
                                        >
                                            Billing
                                        </span>
                                    </>
                                )}
                            </div>
                            <strong className="text-[2rem] leading-tight text-gray-900">
                                {formStep === FormStep.INFO ? 'Create your Agent Account' : 'Secure Payment'}
                            </strong>
                            {selectedPackage?.name === 'SELLING AGENT' && (
                                <>
                                    {formStep === FormStep.INFO ? (
                                        <div className="mt-4 flex w-full px-16">
                                            <p className="w-full text-center">
                                                Already have an account?{' '}
                                                <a
                                                    href="https://agent.realty.com.au/login"
                                                    className="font-semibold text-blue-500   underline"
                                                >
                                                    Login
                                                </a>{' '}
                                            </p>
                                            {/* <div className="text-slate-400">
                             help <FA icon={faQuestionCircle} />
                         </div> */}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>

                        <div className="relative hidden w-full justify-center md:flex">
                            {formStep === FormStep.INFO ? (
                                <Image
                                    src={createAccountHeroImg}
                                    width={359}
                                    height={387}
                                    alt="Hero Image"
                                    className="aspect-square object-cover"
                                />
                            ) : (
                                <Image
                                    src={paymentIllustration}
                                    width={478}
                                    height={397}
                                    alt="Payment Image"
                                    className="aspect-square object-cover"
                                />
                            )}
                        </div>
                    </div>
                    <div className="w-full px-4 py-4 md:w-1/2 md:px-10">
                        <Form name="createAgentAccount" form={form} onFinish={onSubmit}>
                            <div
                                className={clsx('', {
                                    block: formStep === FormStep.INFO,
                                    hidden: formStep !== FormStep.INFO,
                                })}
                            >
                                <AccountInfo
                                    isAgentOld={isAgentOld}
                                    selectedAgent={selectedAgent}
                                    selectedPackage={selectedPackage}
                                    isLoading={loading}
                                    onSubmitClick={() => setFormStep(FormStep.BILLING)}
                                    formInstance={form}
                                />
                            </div>
                            <div
                                className={clsx('', {
                                    block: formStep === FormStep.BILLING,
                                    hidden: formStep !== FormStep.BILLING,
                                })}
                            >
                                <BillingInfo
                                    selectedAgent={selectedAgent}
                                    CardNumberElement={CardNumberElement}
                                    CardExpiryElement={CardExpiryElement}
                                    CardCvcElement={CardCvcElement}
                                    isLoading={loading}
                                    selectedPackage={selectedPackage}
                                    formInstance={form}
                                    formStep={formStep}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateAgentAccountModal;
