import React, { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Input } from 'antd';
import dayjs from 'dayjs';
import {
    CardCvcElementComponent,
    CardExpiryElementComponent,
    CardNumberElementComponent,
} from '@stripe/react-stripe-js';

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import { CreateAgentFormType, FormStep, PackageType, SelectedAgentType } from '~/types/create-agent-form-type';
import { includesIgnoreCase } from '~/utils/string';

import creditCardImages from '../../images/credit-cards-img.png';
import styles from './BillingInfo.module.scss';

type Props = {
    CardNumberElement: CardNumberElementComponent;
    CardExpiryElement: CardExpiryElementComponent;
    CardCvcElement: CardCvcElementComponent;
    isLoading?: boolean;
    selectedAgent: SelectedAgentType;
    selectedPackage: PackageType;
    formInstance: FormInstance<CreateAgentFormType>;
    formStep: FormStep;
};

const BillingInfo = ({
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    isLoading,
    selectedAgent,
    selectedPackage,
    formInstance,
    formStep,
}: Props) => {
    const [couponCodeInfo, setCouponCodeInfo] = useState(null);
    const { validateFields } = formInstance;
    const stripeOptions = {
        options: {
            style: {
                base: {
                    fontSize: '14px',
                    color: 'black',
                    border: '4px',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': { color: '#bfbfbf' },
                },
                invalid: { color: '#9e2146' },
            },
        },
    };

    // const cardElement = elements?.getElement(CardNumberElement);
    // stripe.createToken(cardElement).then((payload) => {
    //     const { token, error } = payload;
    // });

    const couponCode = Form.useWatch('couponCode');

    useEffect(() => {
        validateFields(['couponCode'])
            .then(() => {
                const couponCodeInfoData = selectedPackage?.couponCodes?.[`${couponCode}`.toUpperCase()];
                setCouponCodeInfo(couponCodeInfoData);
            })
            .catch(() => {
                setCouponCodeInfo(null);
            });
    }, [couponCode]);

    return (
        <div className="pt-12">
            <div className="text-lg font-medium">Billing Information</div>
            <div className="mt-2 flex flex-col gap-x-6 sm:flex-row">
                <div className="w-full">
                    <span className="font-medium">First Name</span>
                    <Form.Item
                        name="billingFirstName"
                        className="customRoundedLgInput"
                        initialValue={selectedAgent?.firstName}
                        rules={[{ required: formStep === FormStep.BILLING, message: 'Please input your First Name!' }]}
                    >
                        <Input placeholder="John" style={{ color: 'black', height: '2.5rem' }} />
                    </Form.Item>
                </div>
                <div className="w-full">
                    <span className="font-medium">Last Name</span>
                    <Form.Item
                        name="billingLastName"
                        className="customRoundedLgInput"
                        initialValue={selectedAgent?.lastName}
                        rules={[{ required: formStep === FormStep.BILLING, message: 'Please input your Last Name!' }]}
                    >
                        <Input placeholder="Doe" style={{ color: 'black', height: '2.5rem' }} />
                    </Form.Item>
                </div>
            </div>
            <div className="w-full">
                <span className="font-medium">Email</span>
                <Form.Item
                    name="billingEmail"
                    className="customRoundedLgInput"
                    initialValue={selectedAgent?.email}
                    rules={[
                        {
                            type: 'email',
                            required: formStep === FormStep.BILLING,
                            message: 'Please input your Email!',
                        },
                    ]}
                >
                    <Input placeholder="john.doe@example.com" style={{ color: 'black', height: '2.5rem' }} />
                </Form.Item>
            </div>
            <div className="asm:flex-row mt-6 flex w-full flex-col gap-6">
                <div className="w-full">
                    <div className="flex w-full justify-between">
                        <span>Card number</span>
                        <img src={creditCardImages} alt="Visa or Master Card" className="object-contain pb-1" />
                    </div>
                    <div className={`w-full ${styles.stripeElement}`}>
                        <CardNumberElement {...stripeOptions} />
                    </div>
                </div>
                <div className="flex flex-row gap-6">
                    <div className="w-full">
                        <div className="pb-1">Expiration date</div>
                        <div className={`${styles.stripeElement}`}>
                            <CardExpiryElement {...stripeOptions} />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="pb-1">CVC</div>
                        <div className={`${styles.stripeElement}`}>
                            <CardCvcElement {...stripeOptions} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 w-full">
                <Form.Item name="isSaveBillingInfo" valuePropName="checked" initialValue={true}>
                    <Checkbox value>Save Card Information?</Checkbox>
                </Form.Item>
            </div>

            <hr className="my-4 h-px bg-slate-200" />

            <div className="mt-6 w-full">
                <div className="flex justify-between">
                    <span className="font-medium">Coupon Code</span>
                    <span>{selectedPackage?.couponCodes?.[couponCode]?.title ?? ''}</span>
                </div>
                <Form.Item
                    name="couponCode"
                    className="customRoundedLgInput"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if (
                                    selectedPackage?.couponCodes &&
                                    !includesIgnoreCase(Object.keys(selectedPackage?.couponCodes), value)
                                ) {
                                    return Promise.reject(new Error('Invalid coupon code.'));
                                }

                                if (
                                    selectedPackage?.couponCodes &&
                                    includesIgnoreCase(Object.keys(selectedPackage?.couponCodes), value) &&
                                    selectedPackage?.couponCodes[value]?.expirationDate &&
                                    dayjs().isAfter(selectedPackage?.couponCodes[value]?.expirationDate)
                                ) {
                                    return Promise.reject(new Error('Coupon already expired.'));
                                }

                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="12345678BF  " style={{ color: 'black', height: '2.5rem' }} allowClear />
                </Form.Item>
            </div>

            <div className="flex flex-col pb-4">
                <div className="flex justify-between text-lg font-medium">
                    <div className="text-lg">Total:</div>
                    <div className="flex gap-8">
                        <span>
                            {selectedPackage.name}
                            <span className="font-base text-sm">&nbsp;({selectedPackage.category})</span>
                        </span>
                        <div className="flex">
                            {couponCodeInfo ? (
                                <div>
                                    <span className="text-red-500">*</span>${couponCodeInfo?.finalAmount}{' '}
                                    <span className="text-xs">+ GST</span>
                                </div>
                            ) : (
                                <div>
                                    ${selectedPackage?.price} <span className="text-xs">+ GST</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    {couponCodeInfo?.disclaimer ? (
                        <>
                            <span className="text-red-500">*</span>
                            {couponCodeInfo?.disclaimer}
                        </>
                    ) : null}
                </div>
            </div>
            <div className="w-full">
                <Form.Item>
                    <button
                        disabled={isLoading}
                        type="submit"
                        className="mt-2 w-full rounded-lg bg-blue-500 py-4 font-semibold text-white"
                    >
                        {isLoading && <FA icon={faSpinnerThird} size="lg" spin className="mr-2" />}
                        Subscribe
                    </button>
                </Form.Item>
            </div>
        </div>
    );
};

export default BillingInfo;
