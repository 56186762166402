import React from 'react';
import { Modal } from 'antd';
import Image from 'next/image';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import successImage from '../../images/success-illustration.png';
import { packages } from '~/constants/pre-portal-package';

type Props = {
    selectedPackageName: string;
    isVisible: boolean;
    onCancel: () => void;
};

const SuccessPageModal = ({ isVisible, onCancel, selectedPackageName }: Props) => {
    const redirectLink = () => {
        switch (selectedPackageName) {
            case packages?.SELLING_AGENT.name:
                return 'https://agent.realty.com.au';
            case packages?.BUYING_AGENT.name:
                return 'https://buyer.realty.com.au';
            default:
                return 'https://agent.realty.com.au';
        }
    };

    return (
        <Modal
            visible={isVisible}
            footer={null}
            onCancel={onCancel}
            width={1083}
            closeIcon={<FA icon={faTimes} />}
            destroyOnClose
            centered
            className="createAgentModalStyle"
            maskStyle={{ backgroundColor: 'rgb(17 24 39 / 0.7)', backdropFilter: 'blur(8px)' }}
        >
            <div className="flex w-full flex-col md:flex-row">
                <div className="flex w-full flex-col items-center justify-center rounded-l-lg text-center text-gray-900 md:bg-slate-50">
                    <div className="flex flex-col px-16 py-10 md:py-40">
                        <p className="text-[2rem] font-bold">Congratulations</p>
                        <p className="mt-6 text-xl font-medium tracking-tight md:text-2xl">
                            You have successfully subscribed to {selectedPackageName} package
                        </p>
                        <p className="my-8 text-base font-light">Please check your email for confirmation.</p>
                        <a
                            href={redirectLink()}
                            className="text-lg font-semibold text-primary-500 underline md:text-xl"
                        >
                            Go to your Agent Dashboard
                        </a>
                    </div>
                </div>
                <div className="flex w-full items-center justify-center">
                    <div className="relative">
                        <Image
                            src={successImage}
                            className={'object-contain'}
                            height={404}
                            width={411}
                            alt="Success Image"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessPageModal;
