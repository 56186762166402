import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import Image from 'next/image';
import { useMutation } from '@apollo/client';

import { PackageType } from '~/types/create-agent-form-type';

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import sendMessageIllustration from './images/send-message-illustration.png';
import SendEmailFromPrePortalPreRegister from './Operations.graphql';

import usFlag from '~/assets/flagsForMobileNumber/us-flag.png';
import auFlag from '~/assets/flagsForMobileNumber/au-flag.png';
import nzFlag from '~/assets/flagsForMobileNumber/nz-flag.png';

const { Option } = Select;

type FormType = {
    firstName: string;
    lastName: string;
    contactNumber: string;
    contactNumberCountryCode: number;
    email: string;
    message: string;
};

type Props = {
    isVisible: boolean;
    onCancel: () => void; //Closes the profile search modal
    selectedPackage?: PackageType;
};

const PrePortalPreRegisterModal = ({ isVisible, onCancel, selectedPackage }: Props) => {
    const [sendEmailFromPrePortalPreRegister, { loading }] = useMutation(SendEmailFromPrePortalPreRegister);

    const onSubmit = async (data: FormType) => {
        await sendEmailFromPrePortalPreRegister({
            variables: {
                input: {
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    contactNumberCountryCode: data.contactNumberCountryCode,
                    contactNumber: data?.contactNumber,
                    email: data?.email,
                    packageName: selectedPackage.name,
                    message: data?.message,
                },
            },
        })
            .catch(() => {
                Modal.error({
                    title: 'It seems there was an error in sending your message.',
                    content: (
                        <p>
                            Please contact{' '}
                            <a className="text-black" href="mailto:support@realty.com.au">
                                support@realty.com.au
                            </a>
                        </p>
                    ),
                });
            })
            .then(() => {
                Modal.success({
                    title: 'Your message has been sent!',
                    content: <p>Thank you for applying</p>,
                });
            });

        onCancel();
    };

    return (
        <Modal
            visible={isVisible}
            footer={null}
            onCancel={onCancel}
            width={1083}
            closeIcon={<FA icon={faTimes} />}
            centered
            destroyOnClose
            className="createAgentModalStyle"
            maskStyle={{ backgroundColor: 'rgb(17 24 39 / 0.7)', backdropFilter: 'blur(8px)' }}
        >
            <div className="flex w-full flex-col md:flex-row">
                <div className="flex w-full flex-col items-center justify-between rounded-l-lg px-4 md:bg-slate-50">
                    <div className="pt-8 text-center md:pb-10 lg:px-24 lg:pt-16">
                        <span className="w-full text-[2rem] font-bold leading-[2.625rem] tracking-tight">
                            Send your message to apply
                        </span>
                    </div>

                    <div className="relative hidden md:flex">
                        <Image
                            src={sendMessageIllustration}
                            width={423}
                            height={372}
                            // layout="responsive"
                            className="h-full object-contain"
                            priority
                        />
                    </div>
                </div>
                <div className="w-full">
                    <Form<FormType> name="sendPrePortalPreRegisterEmail" onFinish={onSubmit} className="h-full w-full">
                        <div className="flex h-full w-full flex-col p-4 sm:p-10">
                            <div className="mt-2 flex flex-col gap-x-6 sm:flex-row">
                                <div className="w-full">
                                    <span className="font-medium">First Name</span>
                                    <Form.Item
                                        name="firstName"
                                        className={`customRoundedLgInput`}
                                        rules={[{ required: true, message: 'Please input your First Name' }]}
                                    >
                                        <Input placeholder="John" style={{ color: 'black', height: '2.5rem' }} />
                                    </Form.Item>
                                </div>
                                <div className="w-full">
                                    <span className="font-medium">Last Name</span>
                                    <Form.Item
                                        name="lastName"
                                        className={`customRoundedLgInput`}
                                        rules={[{ required: true, message: 'Please input your Last Name' }]}
                                    >
                                        <Input placeholder="Doe" style={{ color: 'black', height: '2.5rem' }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="w-full">
                                <span className="font-medium">Email</span>
                                <Form.Item
                                    name="email"
                                    className={`customRoundedLgInput`}
                                    rules={[{ type: 'email', required: true, message: 'Please input your Email' }]}
                                >
                                    <Input
                                        placeholder="john.doe@example.com"
                                        style={{ color: 'black', height: '2.5rem' }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full ">
                                <span className="font-medium">Mobile Number</span>
                                <div className="flex w-full items-center ">
                                    <Form.Item
                                        name="contactNumberCountryCode"
                                        className="w-[5.438rem] rounded-l-lg border-y-[1px] border-l-[1px]"
                                        rules={[{ required: true }]}
                                        initialValue={61}
                                    >
                                        <Select bordered={false} className="h-10 border-t-4 border-transparent">
                                            <Option value={61}>
                                                <div className="flex items-center justify-between">
                                                    <img src={auFlag} alt="AU Flag" width={18} /> +61
                                                </div>
                                            </Option>
                                            <Option value={1}>
                                                <div className="flex items-center justify-between">
                                                    <img src={usFlag} alt="US Flag" width={18} /> +1
                                                </div>
                                            </Option>
                                            <Option value={64}>
                                                <div className="flex items-center justify-between">
                                                    <img src={nzFlag} alt="NZ Flag" height={14} width={18} /> +64
                                                </div>
                                            </Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="contactNumber"
                                        className="w-full flex-1 rounded-r-lg border-y-[1px] border-r-[1px]"
                                        rules={[{ required: true, message: 'Please input your Mobile Number' }]}
                                    >
                                        <Input
                                            bordered={false}
                                            placeholder="Mobile number"
                                            className="h-10"
                                            style={{ marginLeft: -10 }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="w-full">
                                <span className="font-medium">Email</span>
                                <Form.Item
                                    name="message"
                                    className={`customRoundedLgInput`}
                                    rules={[{ required: true, message: 'Please input your message' }]}
                                >
                                    <Input.TextArea
                                        placeholder="Type your message here"
                                        style={{ color: 'black' }}
                                        rows={5}
                                    />
                                </Form.Item>
                            </div>
                            <div className="mt-2 w-full">
                                <button
                                    type="submit"
                                    className="w-full rounded-lg bg-blue-500 py-4 font-semibold text-white"
                                >
                                    {loading && <FA icon={faSpinnerThird} size="lg" spin className="mr-2" />}
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default PrePortalPreRegisterModal;
