import React, { useState } from 'react';
import Image from 'next/image';

import { Modal } from 'antd';
import { PackageType, SelectedAgentType } from '~/types/create-agent-form-type';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import dummyProfileImg from './images/dummy-profile-img.png';
import AgentProfileSearch from './components/AgentProfileSearch';
import CreateAgentAccountModal from '~/components/CreateAgentAccountModal';

type Props = {
    isVisible: boolean;
    onCancel: () => void; //Closes the profile search modal
    selectedPackage?: PackageType;
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);

const ExistingAgentSearchModal = ({ isVisible, onCancel, selectedPackage }: Props) => {
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

    const handleSelectAgent = (agent: SelectedAgentType) => {
        setSelectedAgent(agent);
        setShowCreateAccountModal(true);
    };

    const handleCancel = () => {
        setSelectedAgent(null);
        setShowCreateAccountModal(false);
    };

    return (
        <>
            <Elements stripe={stripePromise}>
                <CreateAgentAccountModal
                    selectedAgent={selectedAgent}
                    isVisible={showCreateAccountModal}
                    onCancel={handleCancel}
                    selectedPackage={selectedPackage}
                />
            </Elements>
            <Modal
                visible={isVisible}
                footer={null}
                onCancel={onCancel}
                width={1083}
                closeIcon={<FA icon={faTimes} />}
                centered
                destroyOnClose
                className="existingAgentSearchModalStyle"
                maskStyle={{ backgroundColor: 'rgb(17 24 39 / 0.7)', backdropFilter: 'blur(8px)' }}
            >
                <div className="flex w-full items-center justify-center">
                    <div className="w-full max-w-screen-xl">
                        <div className="flex flex-col gap-x-6 overflow-hidden sm:flex-row">
                            {/* <div className="hidden w-1/2 flex-col items-center gap-y-10 rounded-l-lg bg-slate-50 py-16 md:flex lg:px-14 xl:px-20"> */}
                            <div className="flex w-full flex-col items-center gap-y-10 rounded-l-lg py-16 sm:w-1/2 sm:bg-slate-50 lg:px-14 xl:px-20">
                                <span className="w-full whitespace-break-spaces text-center text-[2rem] font-bold leading-[2.625rem] tracking-tight">
                                    Do you have an existing Realty profile?
                                </span>
                                <div className="relative hidden w-fit sm:block">
                                    <Image
                                        src={dummyProfileImg}
                                        height={272}
                                        width={165}
                                        alt="Profile Image"
                                        className="object-contain"
                                    />
                                </div>
                            </div>
                            {/* <div className="flex h-auto min-h-[28.125rem] w-full items-center justify-center px-8 md:w-1/2"> */}
                            <div className="flex h-auto min-h-[28.125rem] w-full justify-center px-8 sm:w-1/2 sm:items-center ">
                                <AgentProfileSearch
                                    onNewAgent={() => setShowCreateAccountModal(true)}
                                    onSelectAgent={handleSelectAgent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ExistingAgentSearchModal;
