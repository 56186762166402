import { AgentType, PackagePromotions, PackageType } from '~/types/create-agent-form-type';

export const packages: { [key: string]: PackageType } = {
    SELLING_AGENT: {
        name: 'SELLING AGENT',
        category: null,
        type: AgentType.SELLER,
        price: 0,
        package: null,
    },
    BUYING_AGENT: {
        name: 'BUYING AGENT',
        category: 'BASIC',
        type: AgentType.BUYER,
        price: 97,
        package: PackagePromotions.BUYER_BASIC_MONTHLY,
        couponCodes: {
            OSPVIP: {
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-23T13:59:00+0000',
            },
            BAIVIP: {
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-14T13:59:00+0000',
            },
            BAIVIP30: {
                title: 'Free 30 day trial',
                finalAmount: 97,
                // disclaimer: null,
                expirationDate: '2024-06-23T13:59:00+0000',
            },
            OSPVIP30: {
                title: 'Pay for 9 months get 12 with 30 days trial',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-28T13:59:00+0000',
            },
            ZACKYPANNUAL: {
                title: 'Pay for 9 months get 12 with 30 days trial',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-21T13:59:00+0000',
            },
            ZACKYP: {
                title: 'Free 30 day trial',
                finalAmount: 97,
                expirationDate: '2024-06-21T13:59:00+0000',
            },
            BASIC26: {
                title: 'Pay for 9 months get 12',
                finalAmount: 873,
                disclaimer: 'Coupon code is available to first year only.',
                expirationDate: '2024-06-28T13:59:00+0000',
            },
        },
    },
    ADVANCED: {
        name: 'DEALS',
        category: 'DEALS',
        type: AgentType.BUYER,
        price: 297,
        package: PackagePromotions.BUYER_ADVANCED_MONTHLY,
        couponCodes: {},
    },
    PRO: {
        name: 'PRO',
        category: 'PRO',
        type: AgentType.BUYER,
        price: 1997,
        package: null,
    },
};
