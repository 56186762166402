import React, { useCallback, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import PRIMARY from '~/constants/colors';
import AgentSuggestionsQuery from '../Operations.graphql';
import { SelectedAgentType } from '~/types/create-agent-form-type';
import { FindAgentSearchSuggestionType } from '~/constants/agent-search-suggestion-type';
import LoadingDots from '~/components/LoadingDots/LoadingDots';

type DropDownProps = {
    isLoading: boolean;
    agentsList: SelectedAgentType[];
    onSelectAgent: (val: string) => void;
};

const SearchAgentDropdown = ({ isLoading, agentsList, onSelectAgent }: DropDownProps) => {
    if (isLoading) return <LoadingDots />;
    if (agentsList?.length <= 0 || agentsList === undefined) return null;

    return (
        <div className="max-h-[12rem] w-full overflow-y-auto overflow-x-hidden rounded-lg border-2 bg-white">
            {agentsList?.map(({ id, firstName, lastName, profilePicture, agency }) => (
                <button
                    className="w-full p-2 duration-300 hover:bg-gray-200"
                    key={id}
                    onClick={() => onSelectAgent(id)}
                >
                    <div className="flex items-center gap-x-4">
                        <Avatar
                            className="min-w-max"
                            src={profilePicture ? `${profilePicture}?w=50` : null}
                            icon={<UserOutlined />}
                            style={{ backgroundColor: agency?.bannerColor || PRIMARY }}
                        />
                        <div className="flex flex-col text-start">
                            {firstName} {lastName}
                            <span className="text-xs">{agency?.name}</span>
                        </div>
                    </div>
                </button>
            ))}
        </div>
    );
};

type Props = {
    onSelectAgent: (val: SelectedAgentType) => void;
    onNewAgent: () => void;
};

const AgentProfileSearch = ({ onSelectAgent, onNewAgent }: Props) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const [getAgents, { loading, data }] = useLazyQuery(AgentSuggestionsQuery);

    const onSearch = useCallback(
        debounce((keyword: string) => handleSearch(keyword), 300),
        []
    );

    const handleSearch = async (keyword: string) => {
        if (keyword) {
            await getAgents({
                variables: {
                    filter: {
                        keyword,
                        first: 20,
                        type: FindAgentSearchSuggestionType.AGENT_BY_NAME,
                    },
                },
            });
        }
    };

    const handleSelect = (val: string) => {
        setShowDropdown(false);
        const agent: SelectedAgentType = data?.findAgentSearchSuggestion?.find(({ id }) => id === val);
        onSelectAgent(agent);
    };

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="w-full px-2">
            <div className="mb-1 font-medium">Search for your Realty.com.au profile</div>
            <div className="relative" ref={ref}>
                <input
                    placeholder="Type your name"
                    className="w-full rounded-lg border-[1px] p-2"
                    onChange={(e) => onSearch(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                    // onBlur={() => setShowDropdown(false)}
                />
                <div
                    className={clsx('absolute w-full overflow-hidden pt-1 duration-300 ease-in-out', {
                        'max-h-[12.5rem]': showDropdown,
                        'max-h-0': !showDropdown,
                    })}
                >
                    <SearchAgentDropdown
                        isLoading={loading}
                        onSelectAgent={handleSelect}
                        agentsList={data?.findAgentSearchSuggestion}
                    />
                </div>
            </div>
            <p className="pt-10 text-center">
                Not on the list?{' '}
                <button onClick={onNewAgent} className="text-blue-500 underline">
                    Sign up here
                </button>
            </p>
        </div>
    );
};

export default AgentProfileSearch;
