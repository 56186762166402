import React from 'react';
import { Form, Input, Radio, Select, Checkbox, FormInstance } from 'antd';
import clsx from 'clsx';

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import usFlag from '~/assets/flagsForMobileNumber/us-flag.png';
import auFlag from '~/assets/flagsForMobileNumber/au-flag.png';
import nzFlag from '~/assets/flagsForMobileNumber/nz-flag.png';

import { AgentType, CreateAgentFormType, PackageType, SelectedAgentType } from '~/types/create-agent-form-type';
import { packages } from '~/constants/pre-portal-package';

const { Option } = Select;

type Props = {
    selectedAgent: SelectedAgentType;
    isAgentOld: boolean;
    selectedPackage: PackageType;
    onSubmitClick: () => void;
    isLoading?: boolean;
    formInstance: FormInstance<CreateAgentFormType>;
};

const AccountInfo = ({
    selectedAgent,
    isAgentOld,
    onSubmitClick = () => {},
    isLoading,
    selectedPackage,
    formInstance: form,
}: Props) => {
    const { validateFields } = form;

    const validateFieldBeforeBill = () => {
        validateFields().then(() => onSubmitClick());
    };

    const agentMembership = Form.useWatch('agentMembership');

    const validateLicenceNumberRequired = (rule, value, callback) => {
        if (agentMembership.includes('OTHER') && agentMembership?.length === 1) {
            callback();
        } else if (!value) {
            callback('Please input your Agent License Number');
        }

        callback();
    };

    return (
        <>
            <div className="mt-2 flex flex-col gap-x-6 sm:flex-row">
                <div className="w-full">
                    <span className="font-medium">First Name</span>
                    <Form.Item
                        name="firstName"
                        className="customRoundedLgInput"
                        rules={[{ required: true, message: 'Please input your First Name' }]}
                    >
                        <Input disabled={isAgentOld} placeholder="John" style={{ color: 'black', height: '2.5rem' }} />
                    </Form.Item>
                </div>
                <div className="w-full">
                    <span className="font-medium">Last Name</span>
                    <Form.Item
                        name="lastName"
                        className="customRoundedLgInput"
                        rules={[{ required: true, message: 'Please input your Last Name' }]}
                    >
                        <Input disabled={isAgentOld} placeholder="Doe" style={{ color: 'black', height: '2.5rem' }} />
                    </Form.Item>
                </div>
            </div>
            <div className="w-full">
                <span className="font-medium">Email</span>
                <Form.Item
                    name="email"
                    className="customRoundedLgInput"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please input your Email',
                        },
                    ]}
                >
                    <Input
                        disabled={isAgentOld}
                        placeholder="john.doe@example.com"
                        style={{ color: 'black', height: '2.5rem' }}
                    />
                </Form.Item>
            </div>
            <div className="w-full ">
                <span className="font-medium">Mobile Number</span>
                <div className="flex w-full items-center ">
                    <Form.Item
                        name="contactNumberCountryCode"
                        className="rounded-l-lg border-y-[1px] border-l-[1px]"
                        rules={[{ required: true }]}
                        initialValue={!isAgentOld ? undefined : 61}
                    >
                        <Select bordered={false} className="h-10 w-[5.438rem] border-t-4 border-transparent">
                            <Option value={61}>
                                <div className="flex items-center justify-between">
                                    <img src={auFlag} alt="AU Flag" width={18} /> +61
                                </div>
                            </Option>
                            <Option value={1}>
                                <div className="flex items-center justify-between">
                                    <img src={usFlag} alt="US Flag" width={18} /> +1
                                </div>
                            </Option>
                            <Option value={64}>
                                <div className="flex items-center justify-between">
                                    <img src={nzFlag} alt="NZ Flag" height={14} width={18} /> +64
                                </div>
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="contactNumber"
                        className="w-full flex-1 rounded-r-lg border-y-[1px] border-r-[1px]"
                        rules={[{ required: true, message: 'Please input your Mobile Number' }]}
                    >
                        <Input
                            bordered={false}
                            placeholder="Mobile number"
                            className="h-10"
                            style={{ marginLeft: -10 }}
                        />
                    </Form.Item>
                </div>
            </div>
            <hr className="mb-4 h-px bg-slate-200" />
            <div className="w-full">
                <span className="font-medium">Agency Name</span>
                <Form.Item
                    name="agencyName"
                    className={clsx(`customRoundedLgInput`, {
                        'bg-stone-100': isAgentOld && !!selectedAgent?.agency?.name,
                    })}
                    rules={[{ required: true, message: 'Please input your Agency Name' }]}
                >
                    <Input
                        disabled={isAgentOld && !!selectedAgent?.agency?.name}
                        placeholder="XYZ Agency"
                        style={{ color: 'black', height: '2.5rem' }}
                    />
                </Form.Item>
            </div>
            <div className="flex w-full items-start space-x-4">
                <span className="pt-1 font-medium">Agent Type:</span>
                <Form.Item name="agentType" initialValue="BUYER">
                    <Radio.Group>
                        <Radio value={AgentType.BUYER} disabled={selectedPackage?.name === packages.SELLING_AGENT.name}>
                            Buyer Agent
                        </Radio>
                        <Radio value={AgentType.SELLER} disabled={selectedPackage?.name === packages.BUYING_AGENT.name}>
                            Selling Agent
                        </Radio>
                        <Radio value={AgentType.BUYER_SELLER} disabled>
                            Both
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </div>
            <div className="flex w-full items-start space-x-4">
                <span className="pt-1 font-medium">Member of:</span>
                <Form.Item name="agentMembership" rules={[{ required: true, message: 'Please select at least 1' }]}>
                    <Checkbox.Group>
                        <Checkbox value="PIPA">PIPA</Checkbox>
                        <Checkbox value="REBAA">REBAA</Checkbox>
                        <Checkbox value="BAI">BAI</Checkbox>
                        <Checkbox value="OTHER">OTHER</Checkbox>
                    </Checkbox.Group>
                </Form.Item>
            </div>
            <div className="w-full">
                <span className="font-medium">Agent License No.</span>
                <Form.Item
                    name="agentLicenseNumber"
                    className="customRoundedLgInput"
                    rules={[
                        // { required: true, message: 'Please input your Agent License Number' },
                        { validator: validateLicenceNumberRequired },
                    ]}
                >
                    <Input placeholder="123 456 7890" className="h-10" />
                </Form.Item>
            </div>
            <div className="w-full">
                <Form.Item>
                    <button
                        disabled={isLoading}
                        // type="submit"
                        type={selectedPackage?.name !== 'SELLING AGENT' ? 'button' : 'submit'}
                        onClick={() => (selectedPackage?.name !== 'SELLING AGENT' ? validateFieldBeforeBill() : null)}
                        className="w-full rounded-lg bg-blue-500 py-4 font-semibold text-white"
                    >
                        {isLoading && <FA icon={faSpinnerThird} size="lg" spin className="mr-2" />}
                        Create Account
                    </button>
                </Form.Item>
            </div>
        </>
    );
};

export default AccountInfo;
